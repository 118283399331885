module.exports = [{
      plugin: require('/home/gitlab-runner/builds/9faec275/0/syd/v3/blog/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84326468-4","head":true,"anonymize":false},
    },{
      plugin: require('/home/gitlab-runner/builds/9faec275/0/syd/v3/blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gitlab-runner/builds/9faec275/0/syd/v3/blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/gitlab-runner/builds/9faec275/0/syd/v3/blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
